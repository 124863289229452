import { isServer } from "scripts/common"
import isFunction from "lodash/isFunction"
import { createCookie, getCookieValue } from "helpers/cookies"
import { GA_TRACKING_ID, sendPageView } from "./GoogleAnalytics"

export const GTAG_ID = {
  ES: "AW-834922865",
  PT: "AW-741201468",
  IT: "AW-677343420",
  youtube_es: "AW-670471521"
}

export const OPTIMIZE_ID = {
  ES: "GTM-KHBT45W",
  IT: "OPT-MSZ8B4L",
  PT: "OPT-5W4BC87"
}

export function init(countryCode = "ES", pathname) {
  if (isServer) return
  if (!isFunction(window.gtag)) return

  window.gtag("js", new Date())

  window.gtag("config", GA_TRACKING_ID, { optimize_id: OPTIMIZE_ID[countryCode] })
  window.gtag("config", GTAG_ID[countryCode])
  if (countryCode === "ES") window.gtag("config", GTAG_ID.youtube_es)
  if (pathname) sendPageView(pathname)
}

let timeout

export function getExperimentVariation(name, callback) {
  if (isServer) return
  if (timeout) clearTimeout(timeout)

  const cookie = getCookieValue(`op_${name}`)

  if (cookie) {
    callback(cookie)
    return
  }

  if (!isFunction(window.gtag)) {
    // captureException("Gtag not started during experiment")
    callback("0")
    return
  }

  timeout = setTimeout(() => {
    // captureException(`Optimize callback for experiment ${name}`)
    callback("0")
  }, 1000)

  window.gtag("event", "optimize.callback", {
    name,
    callback: val => {
      if (val) {
        clearTimeout(timeout)
        callback(val)
        createCookie(`op_${name}`, val)
        return
      }

      // captureException(`Optimize return no variant for experiment ${name}`)
      clearTimeout(timeout)
      callback("0")
    }
  })
}
