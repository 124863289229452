import Cookies from "universal-cookie"

export function getCookieValue(key) {
  const cookies = new Cookies()
  return cookies.get(key)
}

/**
 * @param {Number} duration How long to store the cookie in days
 */
export function createCookie(key, value, duration = 0) {
  if (!key || !value) return

  const cookies = new Cookies()

  const now = new Date().getTime()
  const expire = now + 60 * 60 * 24 * 1000 * duration

  cookies.set(key, value, {
    path: "/",
    expires: duration ? new Date(expire) : null
  })
}
