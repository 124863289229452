import { isServer, debug, sendToSentry } from "scripts/common"

export const CAR_FORM_ID = "AW-670471521/eGpsCNSQqsIBEOGq2r8C"
export const CALL_FORM_ID = "AW-670471521/UEeSCMr6pMIBEOGq2r8C"
export const VASSLA_FORM_ID = "AW-834922865/nJ_mCIinhtICEPHSj44D"

export const GA_TRACKING_ID = "UA-106978377-1"

export function sendEvent(eventName, eventParameters) {
  if (debug) console.debug(`GA event: "${eventName}" with params ${JSON.stringify(eventParameters)}`)
  if (isServer || debug) return
  try {
    window.gtag("event", eventName, eventParameters)
  } catch (error) {
    sendToSentry("Gtag", error)
  }
}

export function sendPageView(url) {
  if (isServer) return
  if (debug) {
    console.debug(`GA page view: ${url}`)
    return
  }
  try {
    window.gtag("config", GA_TRACKING_ID, { page_path: url })
  } catch (error) {
    sendToSentry("Gtag", error)
  }
}

export function sendLeadEvent(email, online, multistep, formName, sellingStatus) {
  let category = "modal"
  let label = "lead call back"

  if (formName === "vassla") {
    category = "vassla landing"
    label = "lead ebike"
  } else if (multistep) {
    category = "multistep"
    label = "lead multistep"
  } else if (email) {
    category = "car_data"

    if (["pre-owned", "used"].includes(sellingStatus)) label = "lead_preowned"
    else label = "lead"
  }

  sendEvent("lead", {
    event_category: category,
    event_label: label,
    online
  })
}

export function sendVirtualPageView(url, locale) {
  if (!url) return
  sendPageView(`/${locale}/virtualpage${url}`)
}
