import { HYDRATE } from "next-redux-wrapper"
import { EMPTY_CARS_FILTER, UPDATE_CARS_FILTER, SET_PAGE } from "../actions/actions"

export const defaultState = {
  filter: {}
}

function carsReducer(state = defaultState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload }
    case SET_PAGE: {
      return { ...state, page: action.page }
    }
    case UPDATE_CARS_FILTER: {
      return { ...state, filter: action.filter }
    }
    case EMPTY_CARS_FILTER: {
      return { ...state, filter: {} }
    }
    default: {
      return state
    }
  }
}

export default carsReducer
