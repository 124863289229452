export default {
  openGraph: {
    type: "website",
    url: "https://swipcar.com/",
    site_name: "Swipcar",
    title: "Renting de Coches y Vehículos Ocasión al Mejor Precio | Swipcar",
    description:
      "Nº 1 en Renting. Accede a la mayor oferta online. Contrata desde casa. El plan que mejor se adapta a ti. Todo Incluido y Sin Entrada. No compres coche, disfrútalo.",
    locale: "es_ES",
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_CLOUDINARY}/static/home/og-renting-cars.png`,
        width: 1200,
        height: 630,
        alt: "Renting de coches - Swipcar"
      }
    ]
  },
  twitter: {
    handle: "@swipcar_renting",
    site: "@swipcar_renting",
    cardType: "summary_large_image"
  },
  facebook: {
    appId: "357627348037602"
  }
}
