import { applyMiddleware, createStore } from "redux"
import { createWrapper } from "next-redux-wrapper"
import thunkMiddleware from "redux-thunk"
import reducers from "./reducers"

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line
    const { composeWithDevTools } = require("redux-devtools-extension")
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const makeStore = () => createStore(reducers, bindMiddleware([thunkMiddleware]))

const wrapper = createWrapper(makeStore, { debug: false })

export default wrapper
