import { setTag, setUser } from "@sentry/nextjs"
import { isServer } from "scripts/common"

export function init() {
  if (!isServer) setTag("country", (window.countryCode || "ES").toLowerCase())
}

export function personTracking(data) {
  if (!data) return

  setUser({
    id: data.id,
    username: data.name,
    email: data.email
  })
}
