import { isServer, debug, sendToSentry } from "scripts/common"

export function init() {
  setTimeout(() => {
    /* eslint-disable */
    !(function (w, d, t, r, u) {
      let f, n, i
      w[u] = w[u] || []
      f = function () {
        let o = { ti: "26334627" }
        o.q = w[u]
        if (typeof UET !== "undefined") w[u] = new UET(o) || []
        w[u].push("pageLoad")
      }
      n = d.createElement(t)
      n.src = r
      n.async = true
      n.onload = n.onreadystatechange = function () {
        let s = this.readyState
        if (!(s && s !== "loaded" && s !== "complete")) f()
        n.onload = n.onreadystatechange = null
      }
      i = d.getElementsByTagName(t)[0]
      i.parentNode.insertBefore(n, i)
    })(window, document, "script", "https://bat.bing.com/bat.js", "uetq")
    /* eslint-enable */
  }, 3000)
}

export function sendPageView(url) {
  if (isServer) return
  if (debug) {
    console.debug(`BingAds view: ${url}`)
    return
  }
  try {
    window.uetq.push("event", "page_view", { page_path: url })
  } catch (error) {
    sendToSentry("BingAds", error)
  }
}

export function sendLeadEvent(email) {
  if (isServer) return
  if (debug) {
    console.debug("BingAds event: Track lead")
    return
  }
  try {
    window.uetq.push("event", "lead", {
      event_category: "car_data",
      event_label: email ? "lead" : "lead call back"
    })
  } catch (error) {
    sendToSentry("BingAds", error)
  }
}
