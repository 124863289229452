// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, Integrations } from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NODE_ENV === "production") {
  init({
    dsn: SENTRY_DSN || "https://d5672c72c70a4faf9936d70a5680ec34@o286150.ingest.sentry.io/5354455",
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    integrations: [new Integrations.InboundFilters({ ignoreErrors: [/ChunkLoadError/] })],
    ignoreErrors: [
      // Microsoft Outlook crawler compatibility error
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      "Object Not Found Matching Id"
    ]
  })
}
