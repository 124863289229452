import { captureException, withScope } from "@sentry/nextjs"

export const isServer = typeof window === "undefined"

export const debug = process.env.NEXT_PUBLIC_ENVIRONMENT !== "production"

export function sendToSentry(name, error) {
  withScope(scope => {
    scope.setFingerprint([`${name} error`])
    captureException(error)
  })
}
